import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { IPage } from '../Components/Page';
import apiClient, { IEvent } from '../Services/ApiClient';
import { eventsStore } from '../Services/EventsStore';
import { execAsync } from '../Utils';
import Home from './Home';
import Button from '@mui/material/Button';
import { CognexScanner } from "../Scanner/CognexScanner"
import { IScanner } from "../Scanner/IScanner"
import session from '../Services/Session';
import { usePageHost } from '../Components/PageHostContext';
import ScanResult from './ScanResult';
import { beepFail, beepOk, playSoundAsync, vibrate } from '../Services/SoundPlayer';
import { ZXingScanner } from '../Scanner/ZXingScanner';
import { getBackCameraIdAsync } from '../Scanner/Utils';
import ScannerPreview from '../Scanner/ScannerPreview';
import { ICameraScanner } from '../Scanner/ICameraScanner';

interface IScannerProps {
    event?: IEvent;
    gate?: string;
}

const scanner: ICameraScanner = new ZXingScanner();

export function ScannerContent(props: IScannerProps) {

    const gate = props.gate ?? "0";

    const pageHost = usePageHost();

    let [event, setEvent] = useState(null as IEvent);

    if (props.event)
        event = props.event;

    const { eventId } = useParams();

    useEffect(() => {
        if (!event && eventId) {
            execAsync(async () => {
                setEvent(await eventsStore.getByIdAsync(eventId));
            });
        }


    }, []);

    const onScan = () => {

        execAsync(async () => {

            await scanner.openAsync();

            const backCamera = await getBackCameraIdAsync();

            scanner.setCameraAsync(scanner.cameras.findIndex(a => a.deviceId === backCamera));

            const result = await scanner.scanSingleAsync();

            vibrate(true);

            if (result?.code) {

                try {
                    const verResult = await apiClient.verifyTicketAsync({
                        eventID: event.idEvento,
                        barcode: result.code,
                        ref_gate: gate,
                        direction: 'E',
                        ref_device: session.deviceId
                    });

                    if (verResult.type == "OK")
                        playSoundAsync(beepOk);
                    else
                        playSoundAsync(beepFail);

                    console.log(verResult);

                    pageHost.setPage(ScanResult({
                        gate,
                        result: verResult,
                        onScan,
                        title: event.titoloEvento
                    }));
                }
                catch (ex) {
                }
            }

        });
    }

    return (
        <Fragment>
            <header>{event?.titoloEvento}</header>
            <Button size="large" variant="contained" className="scan" onClick={onScan}>Start</Button>
            <div className="gate">
                <label>Gate</label>
                <span>{gate}</span>
            </div>

        </Fragment>
    );
}

const Scanner : IPage = {

    name: "scanner",
    title: "Scanner",
    route: "/scan/:eventId",
    container: Home,
    content: <ScannerContent />
}

export default Scanner;