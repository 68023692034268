import apiClient, { IEvent } from "./ApiClient"

class EventsStore {

    protected _cache: { [id: string]: IEvent } = {};

    async listAsync() {
        const result = await apiClient.getActiveEventsAsync();
        result.forEach(a => this._cache[a.idEvento] = a);
        return result;
    }

    async getByIdAsync(id: string) {

        if (!(id in this._cache)) 
            await this.listAsync();

        return this._cache[id];
    }
}


export var eventsStore = new EventsStore();