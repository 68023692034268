import '../Theme/App.scss';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Login from '../Pages/Login';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { IPage, Page } from './Page';
import EventList from '../Pages/EventList';
import Settings from '../Pages/Settings';
import { useEffect } from 'react';
import { preloadAsync } from '../Services/SoundPlayer';
import Scanner from '../Pages/Scanner';
import ScannerPreview from '../Scanner/ScannerPreview';


const theme = createTheme({
    palette: {
        background: { 
            paper: '#fff',
        },
        text: {
            primary: '#173A5E',
            secondary: '#46505A',
        },
        action: {
            
        }
    },
});

export default function App() {

    const pages: IPage[] = [Login, EventList, Settings, Scanner];

    useEffect(() => {
        preloadAsync();
    }, []);

    const routes = pages.map(a =>
        <Route key={a.name} path={a.route} element={<Page value={a} />} />
    );

    routes.push(
        <Route key="root" path="/" element={<Navigate replace to="/events" />} />);

    return (
        <ThemeProvider theme={theme}>
            <div className="app">
                <BrowserRouter>
                    <Routes>{routes}</Routes>
                </BrowserRouter>
            </div>
            <ScannerPreview />
        </ThemeProvider>
    );
}
