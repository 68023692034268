import { useEffect } from "react";


export function range<T>(a: number, b: number, map: (a: number) => T): T[] {
    const result = [];
    for (let i = a; i <= b; i++)
        result.push(map(i));
    return result;
}

export function useInitAsync(action: () => Promise<any>){
    useEffect(() => {
        action();
    });
}

export async function execAsync<T>(action: () => Promise<T>) {
    return await action();
}

export function generateUUID(){
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
};