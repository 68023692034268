import { Fragment, useEffect, useState } from 'react';
import { IPage, registerPage } from '../Components/Page';
import EventIcon from '@mui/icons-material/Event';
import Home from './Home';
import SearchIcon from '@mui/icons-material/Search';
import { execAsync } from '../Utils';
import { IEvent } from '../Services/ApiClient';
import SearchText from '../Components/SearchText';
import { eventsStore } from '../Services/EventsStore';
import { useNavigate } from 'react-router';
import { Actions } from '../Services/Actions';
import Button from '@mui/material/Button';

function EventListContent() {

    const navigate = useNavigate();

    let [events, setEvents] = useState([] as IEvent[]);

    const [items, setItems] = useState([] as IEvent[]);

    const search = text => {
        const result = text ? events.filter(a => a.titoloEvento.toLowerCase().indexOf(text.toLowerCase()) != -1) : events;
        setItems(result);
    };

    const onEventSelected = (ev: IEvent) => {
        Actions.scan(navigate, ev.idEvento);
    }

    useEffect(() => {
        execAsync(async () => {
            events = await eventsStore.listAsync();
            setEvents(events);
            search("");
        });
    }, []);

    return (
        <Fragment>
            <div className="search-box">
                <SearchIcon />
                <SearchText placeholder="Cerca..." onChanged={text => search(text)} />
            </div>
            <div className="list-view">
                {items.map((a, i) => {

                    let status = "active";

                    return (
                        <div key={i} className="list-item" onClick={_ => onEventSelected(a)}>
                            <div className="main">
                                <header>{a.dataEvento + " " + a.oraEvento}</header>
                                <div className="body">{a.titoloEvento}</div>
                            </div>
                            <div className={"status " + status}>
                                <span className="red">⬤</span>
                                <span className="yellow">⬤</span>
                                <span className="green">⬤</span>
                            </div>
                        </div>
                    );
                })}
            </div >
            <Button variant="contained" className="change-pin" size="large" onClick={() => Actions.login(navigate)}>Cambia pin</Button>
        </Fragment >
    );
}

const EventList: IPage = {

    name: "event-list",
    title: "Eventi",
    route: "/events",
    icon: <EventIcon />,
    content: <EventListContent />,
    container: Home
}

registerPage(EventList);

export default EventList;