export var beepOk: string;
export var beepFail: string;

let sound: HTMLAudioElement;

export async function playSoundAsync(src: string) {

    console.debug("sound START");

    try {
        if (sound == null)
            sound = new Audio();
        sound.src = src;
        await sound.play();
    }
    catch (ex) {

        console.error(ex);
    }

    console.debug("sound END");
}

export async function vibrate(success: boolean) {
    if ("vibrate" in navigator) {
        console.debug("vibrate START");
        if (success)
            navigator.vibrate(200);
        else
            navigator.vibrate([100, 50, 100, 50, 100]);
        console.debug("vibrate END");
    }
}

export async function preloadAsync() {
    beepOk = URL.createObjectURL(await (await fetch("assets/BeepOk.wav")).blob());
    beepFail = URL.createObjectURL(await (await fetch("assets/BeepFail.wav")).blob());
}