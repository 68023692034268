import { useEffect } from "react";
import { useState } from "react";

interface ISearchTextProps {
    onChanged: (value: string) => void;
    placeholder?: string;
}

export default function SearchText(props: ISearchTextProps) {

    const [value, setValue] = useState("");
    let element: HTMLInputElement;
    let lastText = value;

    const pool = (el: HTMLInputElement) => {

        if (el.value != lastText) {
            console.log("changed");
            lastText = el.value;
            setValue(lastText);
            props.onChanged(lastText);
        }

        if (document.activeElement == el)
            setTimeout(() => pool(el), 300);
    }

    return (
        <input placeholder={props.placeholder} type="text" ref={el => element = el} onFocus={ev => pool(ev.target)} />
    );
}
