import React from 'react';
import ReactDOM from 'react-dom';
import App from './Components/App';

async function installAsync() {
    const workerUri = "/ServiceWorker.js";
    const versionUri = "/version";

    const curVer = localStorage.getItem("AppVer") as string;

    let newVer: string;

    try {
        newVer = JSON.parse(await (await fetch(versionUri)).text());
    }
    catch {
        newVer = curVer;
        console.warn("Error fetching version");
    }

    if ("serviceWorker" in navigator) {
        const curReg = await navigator.serviceWorker.getRegistration(workerUri);
        if (curVer !== newVer || !curReg || !curReg.active) {

            localStorage.setItem("AppVer", newVer);

            try {

                if (curReg && curReg.active) {
                    await curReg.update();
                }
                else {
                    await navigator.serviceWorker.register(workerUri);
                }
            }
            catch (ex) {
                console.log(ex);
            }
        }
    };

    if (curVer && curVer !== newVer)
        document.location.reload();
}

async function runAsync() {

    installAsync();

    ReactDOM.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        document.getElementById('root')
    );
}

runAsync();