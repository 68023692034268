import { NavigateFunction } from "react-router-dom";

export namespace Actions {

    export function login(navigate: NavigateFunction) {

        navigate("/login");
    }

    export function eventList(navigate: NavigateFunction) {

        navigate("/events");
    }

    export function scan(navigate: NavigateFunction, eventId: string) {

        navigate("/scan/" + eventId);
    }
}