import { IPage, registerPage } from '../Components/Page';
import SettingsIcon from '@mui/icons-material/Settings';
import Home from './Home';

export function SettingsContent() {
    return (
        <div>Settings</div>
    );
}

const Settings: IPage = {

    name: "settings",
    title: "Settings",
    route: "/settings",
    icon: <SettingsIcon />,
    content: <SettingsContent />,
    container: Home
};

registerPage(Settings);

export default Settings;