
/**************************************/

export function showInfo(text: string, style?: string, timeout = 2000) {

    const info = document.createElement("div");
    info.className = "info-tip-container";
    if (style)
        info.className += " " + style;

    const body = document.createElement("div"); 
    body.className = "body";
    body.innerText = text;
    info.appendChild(body);
     
    document.body.appendChild(info);

    setTimeout(() => {
        addClass(info, "visible");
    }, 0);

    setTimeout(() => {
        removeClass(info, "visible");
    }, timeout);

    setTimeout(() => {
        info.remove();
    }, timeout + 500);
}

/**************************************/

export async function getBackCameraIdAsync() {

    const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "environment" }
    });

    let result: string;

    if (stream) {
        console.log(stream);

        const tracks = stream.getTracks();

        for (const track of tracks) {
            const caps = track.getCapabilities();
            console.log(track.id, caps);
            if (caps.facingMode && caps.facingMode.indexOf("environment") != -1)
                result = caps.deviceId;
            track.stop();
        }
    }

    return result;
}

/**************************************/

export function addClass(element: HTMLElement, className: string) {
    if (!element.classList.contains(className))
        element.classList.add(className);
}

/**************************************/

export function removeClass(element: HTMLElement, className: string) {
    if (element.classList.contains(className))
        element.classList.remove(className);
}

/**************************************/

export function switchClass(element: HTMLElement, toAdd: string, toRemove: string) {
    addClass(element, toAdd);
    removeClass(element, toRemove);
}

/**************************************/

export async function delayAsync(timeMs = 0) {
    return new Promise(res => setTimeout(res, timeMs));
}

/**************************************/

export function element<T extends HTMLElement = HTMLElement>(selector: string): T;

export function element<K extends keyof HTMLElementTagNameMap>(selector: string, tag: K): HTMLElementTagNameMap[K];

export function element(selector: string, tag?: any) {

    return document.querySelector(selector);
}

/**************************************/

export function input(selector: string) {
    return element(selector, "input");
}

/**************************************/

export function onInput(selector: string, handler: (value: string) => void) {
    const element = input(selector);
    element.addEventListener("input", () => handler(element.value));
}

/**************************************/

export function onClick(selector: string, handler: () => void) {
    const button = element(selector);
    button.onclick = handler;
}