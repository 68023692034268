import { useEffect, useState } from 'react';
import session from '../Services/Session';
import { Actions } from '../Services/Actions';
import { useLocation, useNavigate } from 'react-router';
import { BottomNavigation, BottomNavigationAction, Container } from '@mui/material';
import EventList from './EventList';
import { Link } from 'react-router-dom';
import Settings from './Settings';
import { PageHostContext } from '../Components/PageHostContext';
import { IPage, Page } from '../Components/Page';

interface IHomeProps {

    content: IPage;
}


export default function Home(props: IHomeProps) {

    const navigate = useNavigate();

    const location = useLocation();

    let [page, setPage] = useState(props.content);

    useEffect(() => {

        if (!session.isValid()) 
            Actions.login(navigate);
    }, []);

    const pages = [EventList, Settings];

    if (!page)
        setPage(pages.find(a => a.route === location.pathname));

    return (
        <PageHostContext.Provider value={{ setPage }}>
            <div className="home">
                <Container className="content">
                    <Page container={Home} value={page} />
                </Container>
                <BottomNavigation showLabels value={page.name} onChange={(_, newValue) => {
                    setPage(pages.find(a => a.name === newValue));
                }}>
                    {pages.map(a => (<BottomNavigationAction
                        key={a.name}
                        component={Link}
                        to={a.route}
                        label={a.title}
                        value={a.name}
                        icon={a.icon}
                    />))}
                </BottomNavigation>
            </div>
        </PageHostContext.Provider>
    );
}