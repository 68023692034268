import { Component, ReactNode, useState } from 'react';
import Button from '@mui/material/Button';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { range } from '../Utils';

interface IPinPadProps {

    value?: string;

    length: number;

    onValueChanged?: (newValue: string) => void;
}

interface IPinCharState {
    value: string;
    isHidden: boolean;
}

class PinChar extends Component<any, IPinCharState> {

    constructor(props) {
        super(props);
        this.state = {
            isHidden: false,
            value: ""
        }
    }

    render() {
        let className = "";
        if (this.state.value) {
            if (this.state.isHidden)
                className = "hidden";

        }
        else
            className = "empty";

        return (
            < span className={className} > {this.state.value}</span >
        );
    }

    get value() {
        return this.state.value;
    }

    set value(value: string) {
        this.setState({
            value: value,
            isHidden: false
        });
        if (value) {
            setTimeout(() => {
                this.setState({
                    isHidden: true
                });
            }, 500);
        }
    }
}

export default function PinPad(props: IPinPadProps) {

    const [value, setValue] = useState(props.value ?? "");

    const chars: PinChar[] = [];


    const onClick = (symbol: string) => {

        let newValue: string;

        if (symbol == "b") {
            if (value.length > 0) {
                newValue = value.substring(0, value.length - 1);
                chars[value.length - 1].value = "";
            }
        }
        else if (symbol != " ") {
            if (value.length >= props.length)
                return;
            newValue = value + symbol;
            chars[value.length].value = symbol;
        }

        if (newValue !== undefined) {

            setValue(newValue);

            if (props.onValueChanged)
                props.onValueChanged(newValue);
        }
    }

    const symbols = "012345678 9b"

    const buttons = [];
    for (let i = 0; i < symbols.length; i++) {
        let symbol = symbols.charAt(i);
        let content: ReactNode | string;
        if (symbol != "b")
            content = symbol;
        else
            content = <BackspaceIcon />

        buttons.push(<Button key={i} variant="contained" onClick={() => onClick(symbol)}>{content}</Button>)
    }

    return (
        <div className="pinPad">
            <div className="value">
                {range(0, props.length - 1, a => (<PinChar key={a} ref={i => chars[a] = i} />))}
            </div>
            <div className="buttons">
                {buttons}
            </div>
        </div>
    );
}
