import { generateUUID } from "../Utils";

class Session {

    protected _pin: string;
    protected _deviceId: string;

    constructor() {
        this._pin = localStorage.getItem("Code") as string;
        this._deviceId = localStorage.getItem("DeviceId");
        if (!this._deviceId) {
            this._deviceId = generateUUID();
            localStorage.setItem("DeviceId", this._deviceId);
        }

    }   

    get deviceId() {
        return this._deviceId
    }

    isValid() { 
        return this._pin != null;
    }

    async openAsync(pin: string) {
        this._pin = pin;
        localStorage.setItem("Code", pin);
        return true;
    }

    get pin() {
        return this._pin;
    }
}

const session = new Session();

export default session;