import { createElement, Fragment, ReactElement } from "react";

const pages: IPage[] = [];

type PageContainer = (props: { content: IPage }) => ReactElement;

export interface IPage {
    name: string;
    title: string;
    route: string;
    icon?: React.ReactNode
    content: React.ReactNode,
    container?: PageContainer
}

interface IPageProps {
    value: IPage;
    container?: PageContainer;
}

export function Page(props: IPageProps) {

    if (props.value.container && props.container !== props.value.container) {
        const container = createElement(props.value.container, { content: props.value, key: props.value.name });
        return container;
    }

    return (
        <div className={"page " + props.value.name}>
            {props.value.content}
        </div>
    );
}

export function registerPage(page: IPage) {
    pages.push(page);
}