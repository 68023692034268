/// <reference path="../dom.d.ts.ts" />
import { BrowserMultiFormatReader } from "@zxing/library/es2015"
import { IScannerCode, IScanOptions } from "./IScanner";
import { BaseScanner } from "./BaseCameraScanner";
import { addClass, delayAsync, element, input, onClick, onInput, removeClass, showInfo } from "./Utils";

export class ZXingScanner extends BaseScanner {

    protected _reader: BrowserMultiFormatReader;
    protected _scanPromise: Promise<void>;


    constructor() {
        super();
        this._reader = new BrowserMultiFormatReader();
    }

    /**************************************/

    protected getVideo() {
        return element<HTMLVideoElement>("#preview");
    }

    /**************************************/

    protected async openWorkAsync() {
        onInput(".focus", () => this.updateFocus(true));
        onClick(".cancel-scan", () => this.stopScanAsync());
    }

    /**************************************/

    protected updateFocus(isChanged = false) {

        const focusContainer = element(".focus-container");

        const focus = parseFloat(input(".focus").value);

        const videoObj = this.getVideo();

        if (!videoObj?.srcObject)
            return true;

        try {
            const track = (videoObj.srcObject as MediaStream).getVideoTracks()[0];
            const capabilities = track.getCapabilities();

            if ("focusMode" in capabilities && "focusDistance" in capabilities) {

                addClass(focusContainer, "visible");

                if (focus == -1) {
                    track.applyConstraints({
                        advanced: [{
                            focusMode: "continuous"
                        }]
                    });

                    if (isChanged)
                        showInfo("FOCUS Automatico");
                }
                else {
                    const focusDistance = capabilities.focusDistance.min + (capabilities.focusDistance.max - capabilities.focusDistance.min) * (focus / 100);

                    track.applyConstraints({
                        advanced: [{
                            focusMode: "manual",
                            focusDistance
                        }]
                    });
                }
                return true;
            }

            removeClass(focusContainer, "visible");

            if (isChanged)
                showInfo("FOCUS non supportato");

            return false;
        }
        catch (ex) {
            console.log(ex);
            return false;
        }
    }


    /**************************************/

    protected async showScannerAsync() {

        console.debug("showScannerAsync");

        const preview = element(".scan-preview");
        removeClass(preview, "hidden");

        await delayAsync();
        addClass(preview, "visible");
    }

    /**************************************/

    protected async hideScannerAsync() {

        const preview = element(".scan-preview");

        removeClass(preview, "visible");

        setTimeout(() => {
            if (!this._isScanning)
                addClass(preview, "hidden");
        }, 500);
    }

    /**************************************/

    protected async startScanAsync() {

        console.debug("startScanAsync");

        this._isScanning = true;

        await this.showScannerAsync();

        this.onScannerReady(async () => {

            if (!this._isScanning)
                return;

            await this.updateFlashAsync(this._isFlashOn);
            await this.updateFocus(false);
        });
    }

    /**************************************/

    async scanSingleAsync(options?: IScanOptions) {

        console.debug("START scanSingleAsync");

        let scanResolve: () => void;

        this._scanPromise = new Promise(res => scanResolve = res);

        await this.startScanAsync();

        let scanCode: IScannerCode;

        try {

            const result = await this._reader.decodeOnceFromVideoDevice(this._cameras[this._activeCamera].deviceId, "preview");

            console.debug("RESULT scanSingleAsync");

            if (result) {
                scanCode = {
                    type: result.getBarcodeFormat().toString(),
                    code: result.getText()
                };
            }
        }
        catch (ex) {
            console.error(ex);
        }

        console.debug("STOPPING scanSingleAsync");

        await this.stopScanAsync(false);

        if (scanResolve)
            scanResolve();

        console.debug("DONE scanSingleAsync");

        return scanCode;
    }

    /**************************************/

    async startMultiScanAsync(onResult: (result: IScannerCode) => Promise<boolean>, options?: IScanOptions) {

        await this.startScanAsync();

        try {

            await this._reader.decodeFromInputVideoDeviceContinuously(this._cameras[this._activeCamera].deviceId, "preview", async result => {

                if (!result)
                    return;
                 
                const mustContinue = onResult({
                    type: result.getBarcodeFormat().toString(),
                    code: result.getText()
                });

                if (!mustContinue)
                    this.stopScanAsync();
            });

        }
        catch (ex) {
            console.log(ex);
        }
    }

    /**************************************/

    async stopScanAsync(wait = true) {

        console.debug("stopScanAsync");

        if (!this._isScanning) {
            console.warn("not scanning");
            return;
        }

        await this.hideScannerAsync();

        this._reader.stopAsyncDecode();
        this._reader.stopContinuousDecode();
        this._reader.reset();

        if (this._scanPromise) {
            if (wait) {
                console.debug("wait scanPromise");
                await this._scanPromise;
            }
            this._scanPromise = null;
        }

        this._isScanning = false;
        console.debug("stopped");
    }

    /**************************************/

    async setCameraAsync(index: number) {
        this._activeCamera = index == -1 ? 0 : index;
    }
}

