import { createContext, useContext, ReactNode } from "react";
import { IPage } from "./Page";

interface IPageHostContext {
    setPage: (value: IPage) => void;
}

export const PageHostContext = createContext({
    setPage: null
} as IPageHostContext);


export function usePageHost() {
    return useContext(PageHostContext);
}