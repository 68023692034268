import { Fragment, useEffect, useState } from 'react';
import { IPage } from '../Components/Page';
import Home from './Home';
import Button from '@mui/material/Button';
import { IVerifyTicketResponse } from '../Services/ApiClient';
import SuccessIcon from "../Images/ok.svg";
import FailIcon from "../Images/ko.svg";

interface IScanResultProps {
    result: IVerifyTicketResponse;
    gate: string;
    title: string;
    onScan: () => void;
}

export function ScanResultContent(props: IScanResultProps) {

    const isSuccess = props.result.type == "OK";
    return (
        <Fragment>
            <header>
                <div>{props.title}</div>
                <div className="gate">
                    <label>Gate</label>
                    <span>{props.gate}</span>
                </div>
            </header>
     
            <img src={isSuccess ? SuccessIcon : FailIcon}/>
            
            <div className={"message " + (isSuccess ? "success" : "fail")}>
                <p>{props.result.msg}</p>
                {props.result.msg_data ? <p>{props.result.msg_data}</p> : null }
            </div>
            <Button size="large" variant="contained" className="scan" onClick={props.onScan}>Next Scan</Button>
    
        </Fragment>
    );
}

const ScanResult = (props: IScanResultProps) => ({

    name: "scan-result",
    title: "Risultato",
    route: "/scan/result/",
    container: Home,
    content: <ScanResultContent {...props} />
} as IPage);

export default ScanResult;