import PinPad from '../Components/PinPad';
import Container from '@mui/material/Container';
import { IPage, registerPage } from '../Components/Page';
import { Button } from '@mui/material';
import { settings } from '../Services/Settings';
import session from '../Services/Session';
import { execAsync } from '../Utils';
import { useNavigate } from 'react-router';
import { Actions } from '../Services/Actions';

export function LoginContent() {

    const navigate = useNavigate();

    const onPinChanged = (value: string) => {
        execAsync(async () => {
            if (value.length === settings.pinLen) {
                await session.openAsync(value);
                Actions.eventList(navigate);
            }
        });
    }

    return (
        <Container className="content">
            <div className="title">
                Welcome
            </div>
            <PinPad length={settings.pinLen} onValueChanged={onPinChanged} />
            <Button className="no-pin" variant="text">
                Non hai il pin?
            </Button>
        </Container>
    );
}

const Login: IPage = {

    name: "login",
    title: "Login",
    route: "/login",
    content: <LoginContent/>
}

registerPage(Login);

export default Login;