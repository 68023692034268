


export default function ScannerPreview() {

    return (
        <div className="scan-preview hidden">
            <div className="video-container">
                <video id="preview">
                </video>
            </div>
            <button className="cancel-scan">Chiudi</button>
            <div className="focus-container">
                <span>FOCUS</span>
                <input defaultValue="-1" className="focus" type="range" min="-1" max="50" step="0.1" />
            </div>
        </div>
    );
}