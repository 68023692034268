import session from "./Session";
import { settings } from "./Settings";

export interface IEvent {

    titoloEvento: string;
    dataEvento: Date | string;
    oraEvento: string;
    idEvento: string;
}

export interface IVerifyTicketData {
    eventID: string;
    ref_gate: string;
    barcode: string;
    direction: "E" | "X";
    ref_device: string;
}

export interface IVerifyTicketResponse {
    type: "OK" | "KO";
    code: string;
    msg: string;
    msg_data: string;
    color: string;
}

export interface IEventStats {
    Entrati: number;
    Attesi: number;
    Totale: number;
}


class ApiClient {

    protected _accessToken: string = "A4A393fj9dc9498cb9b99383449cv89b";
    protected _baseUrl: string;

    constructor(baseUrl: string, accessToken: string) {
        this._accessToken = accessToken;
        this._baseUrl = baseUrl;
    }

    getActiveEventsAsync() {

        return this.execAsync<IEvent[]>("ntc/AXC_Core/Reader_GetActiveEvents", "GET");
    }

    async verifyTicketAsync(data: IVerifyTicketData) {

        const result = await this.execAsync<IVerifyTicketResponse[]>("ntc/AXC_Core/Reader_doVerifyTicket", "GET", data);
        return result[0];
    }

    async getEventStats(eventId: string) {

        const result = await this.execAsync<IEventStats[]>("ntc/AXC_Core/Reader_GetEventStats", "GET", { eventId });
        return result[0];
    }

    protected async execAsync<T>(url: string, method: string, data?: any): Promise<T> {
        url = this._baseUrl + url + "?";

        data = {
            ...data,
            pin: session.pin,
            token: this._accessToken
        }

        for (const key in data)
            url += key + "=" + encodeURIComponent(data[key]) + "&";

        const response = await fetch(url, {
            method
        });

        const json = await response.text();

        return JSON.parse(json);
    }
}

const apiClient = new ApiClient(settings.apiUrl, settings.accessToken);

export default apiClient;