import { ICameraScanner } from "./ICameraScanner";
import { IScannerCode, IScanOptions } from "./IScanner";

export abstract class BaseScanner implements ICameraScanner {

    protected _activeCamera: number;
    protected _isScanning: boolean;
    protected _cameras: MediaDeviceInfo[];
    protected _isFlashOn: boolean;
    protected _isOpen: boolean;

    constructor() {

        this._activeCamera = 0;
        this._isScanning = false;
        this._cameras = [];
        this._isFlashOn = false;
        this._isOpen = false;
    }


    abstract setCameraAsync(index: number): Promise<void>;

    abstract scanSingleAsync(setting?: IScanOptions): Promise<IScannerCode|null>;

    abstract stopScanAsync(): Promise<void>;

    protected abstract getVideo(): HTMLVideoElement;

    /**************************************/

    async openAsync() {

        if (this._isOpen)
            return;

        await this.openWorkAsync();

        await this.updateCamerasAsync();

        this._isOpen = true;
    }

    /**************************************/

    protected async openWorkAsync()  {

    }

    /**************************************/

    async startMultiScanAsync(onResult: (result: IScannerCode) => Promise<boolean>, options?: IScanOptions) {

        while (true) {
            const result = await this.scanSingleAsync(options);
            if (!result)
                return;
            const mustContinue = await onResult(result);
            if (!mustContinue)
                return;
        }
    }

    /**************************************/

    protected async updateCamerasAsync() {
        this._cameras = [];

        const devices = await navigator.mediaDevices.enumerateDevices();
        for (const device of devices) {
            if (device.kind == "videoinput")
                this._cameras.push(device);
        }
    }

    /**************************************/

    async switchCameraAsync() {

        this._activeCamera = (this._activeCamera + 1) % this._cameras.length;

        await this.setCameraAsync(this._activeCamera);
    }


    /**************************************/

    async updateFlashAsync(isOn: boolean) {

        const videoObj = this.getVideo();

        if (videoObj?.srcObject) {

            try {
                const track = (videoObj.srcObject as MediaStream).getVideoTracks()[0];
                const capabilities = track.getCapabilities();

                if ("torch" in capabilities) {
                    track.applyConstraints({ advanced: [{ torch: isOn }] });
                    this._isFlashOn = isOn;
                    return true;
                }
                return false;
            }
            catch (ex) {
                console.log(ex);
                return false;
            }
        }

        return true;
    }

    /**************************************/

    onScannerReady(action: () => void) {

        const videoObj = this.getVideo();

        const check = () => {
            if (videoObj?.srcObject)
                action();
            else
                setTimeout(check, 100);
        }

        check();
    }

    /**************************************/

    get isScanning() {
        return this._isScanning;
    }

    get isOpen() {
        return this._isOpen;
    }

    get activeCamera() {
        return this._activeCamera;
    }

    get cameras() {
        return this._cameras;
    }
}